import React, { useEffect, useState } from "react"

import Layout from "../../layouts"
import AccountLayout from "../../layouts/account-layout"
import { Box, Flex, Text } from "theme-ui"
import { useAccountContext } from "../../../context/AccountContext"
import Image from "../../new-ui/image"
import CloseIcon from "../../../assets/svg/close.svg"
import ExportIcon from "../../../assets/svg/export.svg"
import { getVariantPrice, formatMoneyAmount } from "../../../utils/prices"
import Medusa from "../../../services/api"
import { navigate } from "gatsby-link"
import {
  handleUrlPrefix,
  simpleWordTranslate,
} from "../../../utils/translations-util"
import { useNotificationContext } from "../../../context/NotificationContext"
import useDrawerHook from "../../../hooks/useDrawerHook"
import Button from "../../new-ui/button"
import {
  trackRemoveFromWishlist,
  trackShareWishlist,
  trackShareWishlistCopyButtonClicked,
} from "../../../services/analytics"
import AddToCart from "../../new-ui/cart/add-to-cart"
import { useInterfaceContext } from "../../../context/InterfaceContext"
import { useStore } from "../../../context/NewStoreContext"

const Wishlist = () => {
  const { id, metadata, removeFromWishList, firstName, lastName, email } =
    useAccountContext()

  const [items, setItems] = useState([])
  const { pushNotification } = useNotificationContext()
  const { drawerContent, drawerVisible, setDrawerContent, setDrawerVisible } =
    useDrawerHook({ config: { asModal: true } })

  const { setWishlistCount } = useInterfaceContext()

  useEffect(() => {
    const load = async () => {
      if (metadata?.wishlist?.length > 0 && Array.isArray(metadata?.wishlist)) {
        setWishlistCount(metadata?.wishlist?.length)

        Medusa.variants
          .list({
            ids: metadata.wishlist.map((wish) => wish.variant_id).join(","),
          })
          .then(({ data }) => {
            const items = data.variants
              .map((variant) => {
                // Append the metadata from accountcontext items
                const metadataItem = metadata?.wishlist?.find(
                  (item) => item.variant_id === variant.id
                )

                return {
                  ...variant,
                  thumbnail: metadataItem?.thumbnail,
                  metadata: metadataItem?.metadata,
                  title: metadataItem?.title,
                  description: metadataItem?.description,
                }
              })
              .reduce((acc, next) => {
                const metadata = next.metadata
                if (metadata?.product_bundle_id) {
                  const bundle = acc?.find(
                    (item) => item.unique_id === metadata.unique_id
                  )

                  if (!bundle) {
                    acc.push({
                      ...bundle,
                      unique_id: metadata.unique_id,
                      thumbnail: metadata.bundle_thumbnail,
                      metadata: {
                        product_handle: metadata.product_handle,
                        collection_slug: metadata.collection_slug,
                      },
                      title: metadata.bundle_title,
                      id: next.id,
                      bundle_url: `${metadata.collection_slug}/${metadata.product_handle}`,
                      description: next.description,
                      items: [next],
                    })
                  } else {
                    bundle.items = [...bundle.items, next]
                    bundle.description = `${bundle.description} & ${next.description}`
                  }
                } else {
                  acc.push(next)
                }

                return acc
              }, [])

            setItems(items)
          })
          .catch((err) => console.error(err))
      }

      items.length <= 1 && setItems([])
    }

    load()
  }, [metadata?.wishlist])

  const ClipboardModal = (url) => (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: ["90vw", "350px"],
      }}
    >
      <Text
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "200px",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        onClick={() => {
          trackShareWishlistCopyButtonClicked({
            firstName: firstName,
            lastName: lastName,
            email: email,
            id: id,
          })
          navigator.clipboard
            .writeText(url)
            .then(() => {
              pushNotification({
                id: "copy-to-clipboard",
                body: "Copied to clipboard",
                dismiss: {
                  duration: 3000,
                },
              })
            })

            .catch(() =>
              pushNotification({
                id: "copy-to-clipboard",
                body: "Please copy the full link to share your wishlist",
                dismiss: {
                  duration: 3000,
                },
              })
            )
        }}
      >
        {url}
      </Text>

      <Button
        sx={{ my: 2, width: "120px" }}
        onClick={() => {
          trackShareWishlistCopyButtonClicked({
            firstName: firstName,
            lastName: lastName,
            email: email,
            id: id,
          })
          navigator.clipboard
            .writeText(url)
            .then(() => {
              pushNotification({
                id: "copy-to-clipboard",
                body: "Copied to clipboard",
                dismiss: {
                  duration: 3000,
                },
              })
            })

            .catch(() =>
              pushNotification({
                id: "copy-to-clipboard",
                body: "Please copy the full link to share your wishlist",
                dismiss: {
                  duration: 3000,
                },
              })
            )
        }}
      >
        Copy
      </Button>
    </Flex>
  )

  const handleShareLink = () => {
    trackShareWishlist({
      firstName: firstName,
      lastName: lastName,
      email: email,
      id: id,
    })
    Medusa.customers
      .retreiveShareToken(id)
      .then(({ data }) => {
        const url = `${window.location.origin}/shared-wishlist?jwt=${data.share_token}`
        setDrawerContent(ClipboardModal(url))
        setDrawerVisible(true)
        //window.open(url, "_blank")
      })
      .catch((err) => console.error(err))
  }

  return (
    <Layout>
      <AccountLayout>
        <Box sx={{ width: "100%" }}>
          <Flex
            sx={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <Text sx={{ fontSize: "21px" }}>My wishlist</Text>
            <Flex
              sx={{ svg: { marginRight: 2 }, alignItems: "center" }}
              onClick={handleShareLink}
            >
              <ExportIcon />
              <Text
                sx={{
                  fontSize: "15px",
                  borderBottom: "brownGrey",
                  cursor: "pointer",
                }}
              >
                Share my wishlist
              </Text>
            </Flex>
          </Flex>
          <WishListItems
            items={items}
            removeFromWishList={removeFromWishList}
          />
          {drawerVisible && drawerContent}
        </Box>
      </AccountLayout>
    </Layout>
  )
}

export const WishListItems = ({ items, removeFromWishList, sx }) => {
  const { cart } = useStore()

  const { setWishlistCount } = useInterfaceContext()

  return (
    <Flex sx={{ flexWrap: "wrap", gap: "12px", my: 4, ...sx }}>
      {items.map((item) => {
        const handle = item.product?.handle

        return (
          <Flex
            key={item.id}
            className="item"
            sx={{
              flexDirection: "column",
              flex: ["1 0 45%", "1 0 30%"],
              maxWidth: ["50%", "33%"],
              mb: [3, 4],
              justifyContent: "space-between",
            }}
          >
            <Flex sx={{ flexDirection: "column" }}>
              <Flex sx={{ position: "relative" }}>
                {item.thumbnail?.gatsbyImageData ? (
                  <Image
                    image={item.thumbnail?.gatsbyImageData}
                    onImageClick={() => {
                      navigate(`/product/${handle}`)
                    }}
                  />
                ) : (
                  <Image
                    src={item.thumbnail}
                    onImageClick={() => {
                      navigate(`/product/${handle}`)
                    }}
                  />
                )}
                {removeFromWishList && (
                  <Flex
                    sx={{
                      position: "absolute",
                      bottom: "auto",
                      right: "0",
                      margin: 3,
                      cursor: "pointer",
                      svg: {
                        width: "20px",
                        height: "20px",
                        fill: "grayscale.white",
                        path: {
                          stroke: "grayscale.white",
                        },
                      },
                    }}
                    onClick={async () => {
                      if (item.unique_id) {
                        item.items.reduce(
                          async (acc, next) => {
                            await acc
                            if (next.metadata.unique_id === item.unique_id) {
                              trackRemoveFromWishlist({
                                product_id: next.product_id,
                                sku: next.sku,
                                variant_id: next.id,
                              })
                              return removeFromWishList({
                                variantId: next.id || next.variant_id,
                              }).then((data) => {
                                data &&
                                  setWishlistCount(
                                    data?.customer?.metadata?.wishlist?.length
                                  )
                              })
                            }
                            return acc
                          },
                          [Promise.resolve()]
                        )
                      } else {
                        trackRemoveFromWishlist({
                          product_id: item.product_id,
                          sku: item.sku,
                          variant_id: item.id,
                        })
                        await removeFromWishList({
                          variantId: item.id || item.variant_id,
                        }).then((data) => {
                          data &&
                            setWishlistCount(
                              data?.customer?.metadata?.wishlist?.length
                            )
                        })
                      }
                    }}
                  >
                    <CloseIcon />
                  </Flex>
                )}
              </Flex>
              <Flex
                sx={{
                  flexDirection: ["column", "row"],
                  justifyContent: ["initial", "space-between"],
                  flexWrap: ["initial", "wrap"],
                  my: 2,
                }}
              >
                <Text
                  sx={{
                    fontSize: "15px",
                    my: [1, 0],
                    flex: [0, "1 0 45%"],
                    maxWidth: ["100%", "50%"],
                  }}
                >
                  {item.title}
                </Text>
                <Text
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    flex: [0, "1 0 45%"],
                    maxWidth: ["100%", "50%"],
                    textAlign: ["start", "end"],
                  }}
                >
                  {formatMoneyAmount(
                    {
                      currencyCode: cart?.region?.currency_code,
                      amount: getVariantPrice(cart, item, true),
                    },
                    0
                  )}
                </Text>
                <Text sx={{ fontSize: "15px", mt: [0, 2] }}>
                  Size: <strong>{item.description}</strong>
                </Text>
              </Flex>
            </Flex>
            <AddToCart variant={item} />
          </Flex>
        )
      })}
    </Flex>
  )
}

export default Wishlist
