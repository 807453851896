import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash-es"
import Medusa from "../../../services/api"
import { navigate } from "gatsby-link"

import { ButtonDisabledOnClick } from "../button"
import { Paragraph, Box } from "theme-ui"
import { useInterfaceContext } from "../../../context/InterfaceContext"
import { useNotificationContext } from "../../../context/NotificationContext"
import { trackAddToCart } from "../../../services/analytics"
import { useStore } from "../../../context/NewStoreContext"

import { Button } from "../../v2/Button"

const AddToCart = ({ variant, collection = "wishlist" }) => {
  const { pushNotification, dismissNotification } = useNotificationContext()
  const { showCart } = useInterfaceContext()
  const { cart, createLineItem } = useStore()
  const [inventory, setInventory] = useState(0)

  useEffect(() => {
    if (cart) {
      const load = async () => {
        const inventory = await Medusa.variants
          .retrieve(variant.id)
          .then(({ data }) => {
            return (
              data.variant.allow_backorder || data.variant.inventory_quantity
            )
          })

        if (inventory) {
          setInventory(inventory)
        }
      }

      load()
    }
  }, [cart])

  const handleAddToCart = async () => {
    if (variant.product.thumbnail && !variant.product.thumbnail.file?.url) {
      variant = {
        ...variant,
        product: {
          ...variant.product,
          thumbnail: {
            file: {
              url: variant.product.thumbnail,
            },
          },
        },
      }
    }

    trackAddToCart(cart, variant.product, variant, 1, collection)

    await createLineItem
      .mutateAsync({
        variant_id: variant.id,
        quantity: 1,
        metadata: variant.metadata,
      })
      .catch((e) => {
        console.log(e)
      })

    pushNotification({
      id: "add-to-cart",
      body: `${variant.product.title} - ${
        variant.quantity ? variant.quantity : 1
      } x ${
        variant?.sku?.startsWith("TT") ? variant.type : variant.title
      } has been added to your cart`,
      desktopBody: (
        <Box>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            Added to cart
          </Paragraph>
          <Paragraph sx={{ marginBlockEnd: 4 }}>
            {`${variant.product.title} - ${
              variant.quantity ? variant.quantity : 1
            } x ${
              variant?.sku?.startsWith("TT") ? variant.type : variant.title
            } has been added to your cart`}
          </Paragraph>
          {/* <Flex sx={{ alignItems: "center" }}>
            <Icon
              name="info"
              size={4}
              sx={{ marginBlockStart: "-2px", marginInlineEnd: 2 }}
            />
            <Paragraph>Pickup in store not possible</Paragraph>
          </Flex> */}
          <Button
            sx={{ width: "100%", marginBlockStart: 6 }}
            onClick={() => {
              dismissNotification("add-to-cart")
              showCart()
            }}
          >
            See the cart
          </Button>
        </Box>
      ),
      mobileBody: (
        <div>{`${variant.quantity ? variant.quantity : 1} item added`}</div>
      ),
      dismiss: {
        duration: 3000,
      },
    })
  }

  if (!inventory) {
    return <b>Out of stock</b>
  }

  if (cart?.items.filter((item) => item.variant_id === variant.id).length) {
    return (
      <ButtonDisabledOnClick
        onClick={() => navigate("/checkout")}
        sx={{ height: "48px", fontWeight: "300", width: "100%" }}
      >
        {"In your cart"}
      </ButtonDisabledOnClick>
    )
  }

  return (
    <ButtonDisabledOnClick
      onClick={handleAddToCart}
      isDisabled={isEmpty(variant)}
      sx={{ height: "48px", fontWeight: "300", width: "100%" }}
    >
      Add to cart
    </ButtonDisabledOnClick>
  )
}

export default AddToCart
